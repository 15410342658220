.App {
    /* text-align: center; */
    color: #1c1d22;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Body {
    flex: 1;
}

html {
    background-color: #fcfcfc;
}

a {
    color: #3863e5;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https:fonts.googleapis.com/css?family=Space+Grotesk);
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

* {
    font-family: 'Space Grotesk';
    font-weight: 400;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fcfcfc;
}

.App-link {
    color: #61dafb;
}

.Input {
    display: block;
}

p {
    font-size: 1rem;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px;
}

li {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px;
}

span {
    font-family: 'Inter';
    font-size: 16px;
    line-height: 24px;
}

img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* This makes the buttons and inputs be the logical width */
input {
    box-sizing: border-box;
}

/* Centers placeholder text */
::-webkit-input-placeholder {
    text-align: center;
}
:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}
::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}
:-ms-input-placeholder {
    text-align: center;
}
#react-app ~ iframe {
    visibility: hidden;
}

header {
    padding-top: 12px;
}
